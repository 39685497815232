<template>
  <div class="ACE1Pro">
    <div class="banner">
      <div class="title">ACE 1 Pro</div>
      <div class="smallTitle">Above mainstream performance</div>
      <div class="osDMS w">
        <div class="img">
          <img src="@/assets/ET 7页面配图/资源 12ET7产品介绍.png" alt="" />
        </div>
        <div class="img">
          <img src="@/assets/ET 7页面配图/资源 1@4x2.png" alt="" />
        </div>
      </div>
      <div></div>
    </div>
    <div class="video">
      <video
        src="../../../assets/ACE 1 Pro页面配图/Compax_Ace_1_Pro_Video.mp4"
        autoplay
        loop
        muted
        webkit-playsinline
        playsinline
      ></video>
    </div>
    <div class="display">
      <div class="top">
        <div class="title">Single and double , more combinations</div>
        <div class="text">Wide angle adjustable screen</div>
      </div>
      <div class="img">
        <img src="../../../assets/ACE 1 Pro页面配图/第二页配图.png" alt="" />
      </div>
      <div class="left">
        <div class="title">Main Display</div>
        <div class="text">15.6” 1920 x 1080</div>
        <div class="text">Multi-touch capacitive screen</div>
      </div>
      <div class="right">
        <div class="title">Secondary Display</div>
        <div class="text">10.1” 1280 x 800</div>
      </div>
    </div>
    <div class="anodisedAuminium">
      <div class="title">Anodised Aluminium</div>
      <div class="img">
        <img src="../../../assets/ACE 1 Pro页面配图/第三页配图.png" alt="" />
      </div>
    </div>
    <div class="High-speed">
      <div class="title">High-speed Printer</div>
      <div class="img">
        <img src="../../../assets/ACE 1 Pro页面配图/第四页配图.png" alt="" />
      </div>
    </div>
    <div class="product">
      <div class="line">
        <img src="../../../assets/ACE 1 Pro页面配图/分组 4M.png" alt="" />
      </div>
      <div class="topLeft">
        <div class="text">10-point capacitive touch screen</div>
      </div>
      <div class="topRight"><div class="text">7H hardness glass</div></div>
      <div class="rightCon">
        <div class="text">Fully laminated process</div>
      </div>
      <div class="bottomRight">
        <div class="text">80mm thermal printer with</div>
        <div class="text">Socket communication for agile</div>
        <div class="text">and rapid development</div>
      </div>
      <div class="bottomLeft">
        <div class="text">Aluminum alloy</div>
        <div class="text">structurally stable and lightweight</div>
      </div>
    </div>
    <div class="ACE1ProCon">
      <div class="titleBox">
        <div class="title">ACE 1 Pro</div>
      </div>
      <div class="itemBox">
        <div class="one">
          <div class="itemA">
            <div class="title">OS</div>
            <div class="text">Android 11</div>
          </div>
          <div class="itemA">
            <div class="title">Memory</div>
            <div class="text">4G DDR4+32G eMCC</div>
          </div>
          <div class="itemB">
            <div class="title">CPU</div>
            <div class="text">RK3568, Qual-core, Cortex A55, 2.0 GHz Max</div>
          </div>
          <div class="itemA">
            <div class="title">Printer</div>
            <div class="text">80mm High-speed printers</div>
          </div>
          <div class="itemA">
            <div class="title">Speaker</div>
            <div class="text">5W Stereo</div>
          </div>
          <div class="itemA">
            <div class="title">WiFi</div>
            <div class="text">2.4G/5G dual-band Wi-Fi</div>
          </div>
          <div class="itemA">
            <div class="title">BT</div>
            <div class="text">BT5.0</div>
          </div>
          <div class="itemA">
            <div class="title">Power Adapter</div>
            <div class="text">Input: 24V/2.75A</div>
          </div>
          <div class="itemA">
            <div class="title">Housing</div>
            <div class="text">Anodised Aluminium</div>
          </div>
          <div class="itemA">
            <div class="title">Color</div>
            <div class="text">Space Black / Space Gray</div>
            <div class="text">Midnight</div>
          </div>
          <div class="itemA">
            <div class="title">Certification</div>
            <div class="text">CCC, CE, FCC</div>
          </div>
          <div class="itemC">
            <div class="title">Peripheral Ports</div>
            <div class="text">RJ45*1DC-IN*1</div>
            <div class="text">RJ12*1RJ11*1U</div>
            <div class="text">SB2.0*4TF</div>
            <div class="text">Expansion</div>
            <div class="text">card*13.5mm</div>
            <div class="text">Audio output*1</div>
          </div>
          <div class="itemC">
            <div class="title">Environment</div>
            <div class="text">0 ~ 40℃</div>
            <div class="text">Huminity：</div>
            <div class="text">5%~90%RH</div>
            <div class="text">Storage temperature:</div>
            <div class="text">-12 ~ 40℃</div>
            <div class="text">Huminity：</div>
            <div class="text">10%~90%RH</div>
          </div>
          <div class="itemD">
            <div class="leftText">
              <div class="title">Dimensions (L*W*H)</div>
              <div class="text">363mm*210mm*375mm</div>
            </div>
            <div class="rightImg">
              <div class="top textsmall">363mm</div>
              <div class="left textsmall">375mm</div>
              <div class="right textsmall">210mm</div>
              <div class="img">
                <img
                  src="../../../assets/ACE 1 Pro页面配图/ACE 1 Pro.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.ACE1Pro {
  .banner {
    padding-top: 14px;
    height: 419px;
    background: url('../../../assets/ACE 1 Pro页面配图/首页配图.png') no-repeat
      center;
    background-size: cover;
    .title {
      line-height: 37.65px;
      color: rgba(0, 92, 255, 1);
      text-align: center;
      font-size: 26px;
      font-weight: 700;
    }
    .smallTitle {
      margin-top: 5px;
      text-align: center;
      font-size: 16px;
      line-height: 23.17px;
      font-weight: 500;
      color: #fff;
    }
    .osDMS {
      margin: 21.95px auto 0px;
      width: 241px;
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      .img {
        width: 114px;
        height: 22.14px;
        background-clip: padding-box;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .video {
    height: 219.42px;
    video {
      width: 100%;
      height: 100%;
      object-fit: fill;
      object-position: 50% 60%;
    }
  }
  .display {
    height: 316px;
    background: rgba(0, 0, 0, 1);
    position: relative;
    padding-top: 5px;
    .top {
      text-align: center;
      .title {
        font-size: 16px;
        font-weight: 700;
        line-height: 25px;
        color: rgba(255, 255, 255, 1);
      }
      .text {
        font-size: 12px;
        font-weight: 500;
        line-height: 25px;
        color: rgba(0, 92, 255, 1);
      }
    }
    .img {
      margin: 18px auto 0px;
      width: 290px;
      height: 163px;
      border-radius: 10px;
      border: 2px solid rgba(128, 128, 128, 1);
      img {
        border-radius: 10px;
        width: 100%;
        height: 100%;
      }
    }
    .left {
      position: absolute;
      top: 263px;
      left: 39px;
      width: fit-content;
      text-align: center;
      .title {
        font-size: 12px;
        line-height: 25px;
        font-weight: 700;
        color: rgba(0, 92, 255, 1);
      }
      .text {
        color: rgba(255, 255, 255, 1);
        font-size: 10px;
        line-height: 14.48px;
      }
    }
    .right {
      position: absolute;
      top: 263px;
      left: 225px;
      width: fit-content;
      text-align: center;
      .title {
        font-size: 12px;
        line-height: 25px;
        font-weight: 700;
        color: rgba(0, 92, 255, 1);
      }
      .text {
        color: rgba(255, 255, 255, 1);
        font-size: 10px;
        line-height: 14.48px;
      }
    }
  }
  .anodisedAuminium {
    background: rgba(0, 0, 0, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      padding: 60px 0px 10px 0px;
      color: rgba(255, 255, 255, 1);
      font-size: 16px;
      line-height: 23.17px;
      font-weight: 700;
    }
    .img {
      border-radius: 10px;
      border: 2px solid rgba(128, 128, 128, 1);
      width: 290px;
      height: 163px;
      img {
        border-radius: 10px;
        width: 100%;
        height: 100%;
      }
    }
  }
  .High-speed {
    background: rgba(0, 0, 0, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 53px;
    .title {
      padding: 60px 0px 10px 0px;
      color: rgba(255, 255, 255, 1);
      font-size: 16px;
      line-height: 23.17px;
      font-weight: 700;
    }
    .img {
      border-radius: 10px;
      border: 2px solid rgba(128, 128, 128, 1);
      width: 290px;
      height: 163px;
      img {
        border-radius: 10px;
        width: 100%;
        height: 100%;
      }
    }
  }
  .product {
    position: relative;
    height: 346px;
    background: url('../../../assets/ACE 1 Pro页面配图/第五页爆炸图.png')
      no-repeat center;
    background-size: cover;
    .line {
      width: 296px;
      height: 239px;
      position: absolute;
      top: 30px;
      left: 32px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .text {
      color: rgba(255, 255, 255, 1);
      font-size: 10px;
      line-height: 14.48px;
    }
    .topLeft {
      position: absolute;
      top: 12px;
      left: 34px;
    }
    .topRight {
      position: absolute;
      top: 12px;
      right: 28px;
    }
    .rightCon {
      position: absolute;
      bottom: 160px;
      right: 28px;
    }
    .bottomRight {
      text-align: end;
      position: absolute;
      top: 276px;
      right: 32px;
    }
    .bottomLeft {
      position: absolute;
      top: 276px;
      left: 19px;
    }
  }
  .ACE1ProCon {
    background: rgba(0, 0, 0, 1);
    .titleBox {
      margin-left: 30px;
      padding: 35px 0px 9px 0px;
      position: relative;
      font-weight: 500;
      line-height: 25px;
      color: rgba(0, 92, 255, 1);
      font-size: 20px;
      border-bottom: 4px solid rgba(0, 92, 255, 0.5);
      &::after {
        position: absolute;
        left: 0px;
        bottom: -4px;
        content: '';
        width: 53px;
        height: 0px;
        border-bottom: 4px solid rgba(0, 92, 255, 0.5);
        background: rgba(0, 92, 255, 1);
      }
    }
    .itemBox {
      margin-top: 13px;
      padding: 0px 20px;
      .title {
        font-size: 12px;
        font-weight: 500;
        line-height: 30px;
        color: rgba(255, 255, 255, 1);
      }
      .text {
        font-size: 10px;
        font-weight: 500;
        line-height: 14.48px;
        color: rgba(128, 128, 128, 1);
      }
      .itemA {
        margin-top: 9px;
        padding: 3px 0px 0px 20px;
        width: 168px;
        height: 80px;
        opacity: 1;
        border-radius: 11.43px;
        background: rgba(27, 32, 48, 1);
      }
      .itemB {
        margin-top: 9px;
        padding: 3px 0px 0px 20px;
        width: 349px;
        height: 80px;
        opacity: 1;
        border-radius: 11.43px;
        background: rgba(27, 32, 48, 1);
      }
      .itemC {
        margin-top: 9px;
        padding: 3px 0px 0px 20px;
        width: 168px;
        height: 149px;
        opacity: 1;
        border-radius: 11.43px;
        background: rgba(27, 32, 48, 1);
      }
      .itemD {
        position: relative;
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 349px;
        height: 162px;
        opacity: 1;
        border-radius: 11.43px;
        background: rgba(0, 92, 255, 0.5);
        .text {
          font-size: 10px;
          color: rgba(255, 255, 255, 1);
        }
        .rightImg {
          color: rgba(255, 255, 255, 1);
          font-size: 12px;
          font-weight: 500;
          position: absolute;
          top: 7px;
          left: 124px;
          width: 211px;
          height: 152px;
          .textsmall {
            font-size: 8px;
          }
          .img {
            width: 100%;
            height: 100%;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .top {
            position: absolute;
            left: 180px;
            bottom: 136px;
          }
          .left {
            position: absolute;
            top: 75px;
            left: 0px;
          }
          .right {
            position: absolute;
            top: 120px;
            left: 180px;
          }
        }
      }
      .one {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-flow: wrap;
      }
    }
  }
}
</style>
